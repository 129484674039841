<div class="logInContainer">
  <div class="leftSection">
    <form *ngIf="showLoginInput" class="form-horizontal" #loginForm="ngForm" (ngSubmit)="onSubmit()">
      <div class="form-content">
        <div *ngIf="!showValidationCodeStep" class="form-items">
          <img class="logo-img" src="assets/images/logo-healthexam.svg" alt="" height="40">
          <span class="welcome-text">Welcome to <strong>Health Exam</strong>.</span>
          <div *ngIf="error" class="alert alert-danger p-2" role="alert">
            {{ error }}
          </div>
          <div class="form-floating mb-2">
            <input type="email" name="email" class="form-control" id="email" placeholder="Enter Your Email Address"
              [(ngModel)]="email" [ngClass]="{ 'is-invalid': emailField && emailField.touched && emailField.invalid }"
              #emailField="ngModel" required email autofocus />
            <div *ngIf="emailField && emailField.errors" class="invalid-feedback">
              <div *ngIf="emailField.errors['required']">Email is required</div>
              <div *ngIf="emailField.errors['email']">Email must be a valid email address</div>
            </div>
            <label for="email">Email address</label>
          </div>
          <div class="mt-3 d-grid">
            <button id="loginBtn" class="btn btn-primary" type="submit" [disabled]="inProgress">
              {{inProgress ? 'Please wait...' : 'Log In'}}
            </button>
          </div>
        </div>
        <div *ngIf="showValidationCodeStep" class="form-items">
          <img class="logo-img" src="assets/images/logo-healthexam.svg" alt="" height="40">
          <span class="welcome-text">Verify Login Code</span>
          <p class="text-center mt-4">
            Please enter the 6 digit code sent to <strong>{{partialPhone}}</strong>.
          </p>
          <div class="mb-2 text-center">
            <ng-otp-input (onInputChange)="onOtpChange($event)" [config]="otpConfig"></ng-otp-input>
            <div *ngIf="error" class="text-danger">{{error}}</div>
          </div>
          <div class="mt-3 d-grid">
            <button id="verifyCodeBtn" class="btn btn-primary" type="submit" [disabled]="inProgress">
              {{inProgress ? 'Please wait...' : 'Verify'}}
            </button>
            <span class="text-center mt-2 text-small">
              Didn't receive the code?
              <span [ngClass]="{'text-primary': secondsCountdown === 0}" role="button"
                (click)="sendCode()">Resend</span>
              <span *ngIf="secondsCountdown > 0"> in {{secondsCountdown}} Seconds</span>
            </span>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="mt-5 text-center">
          <p>© {{year}} Health Exam. | <a href="#">Contact Us</a></p>
        </div>
      </div>
    </form>
    <div *ngIf="!showLoginInput" class="form-content">
      <div *ngIf="!inProgress" class="form-items">
        <img class="logo-img" src="assets/images/logo-healthexam.svg" alt="" height="40">
        <span class="welcome-text">Welcome to <strong>Health Exam</strong>.</span>
        <span class="fw-bold fs-6 mb-2 d-block">Pick an account.</span>
        <div class="user-button d-flex justify-content-between align-items-center" role="button"
          (click)="selectUser(uc)" *ngFor="let uc of usersCache">
          <i class="fa fa-user fs-4" aria-hidden="true"></i>
          <div class="me-3 text-dark">{{uc.userName}}</div>
          <i class="fa fa-times" title="Sign out and forget" (click)="removeAccount(uc.userName)"></i>
        </div>
        <div class="mt-5 text-center text-primary">
          <span role="button" (click)="showLoginInput = true">Use another account</span>
        </div>
      </div>
      <div *ngIf="inProgress" class="row d-flex justify-content-center full-height">
        <div class="col-12 align-self-center">
          <div class="row">
            <div class="mx-auto my-auto">
              <div class="text-center">
                <div class="spinner-border thumb-lg text-primary" role="status"></div>
                <h5 class="text-muted mt-2">Please wait...</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="rightSection">
    <p class="login-text">Expediting access to care, advance the continuity of <strong>life.</strong>
    </p>
    <div class="apps">
    <!--  <img src="assets/images/charts.png" alt="chart-icon">
      <img src="assets/images/auth.png" alt="chart-icon">
      <img src="assets/images/ref.png" alt="chart-icon">
      <img src="assets/images/remit.png" alt="chart-icon">
      <img src="assets/images/biller.png" alt="chart-icon">
      <img src="assets/images/sc.png" alt="chart-icon">
      <img src="assets/images/supply.png" alt="chart-icon"> -->
    </div>
  </div>
</div>